import * as React from "react"
import Layout from "../components/layout/Layout";
import styled from "styled-components";

const Fluid = styled.div`
  // calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])))
  //font-size: calc(22.5px + (22.5 - 16) * ((100vw - 425px) / (768 - 425)));
  //font-size: calc(1rem + (1.406rem - 1rem) * ((100vw - 26.563rem) / (48rem - 26.563rem)));
  font-size: calc(1rem + (1.406 - 1) * ((100vw - 26.563rem) / (48 - 26.563)));
  line-height: 1.406rem;

  @media (min-width: 768px) {
    font-size: 1.406rem;
  }

  @media (max-width: 425px) {
    font-size: 1rem;
  }
`

// markup
const FluidFont = () => {
    return (
        <Layout>
            <Fluid>
                Let’s discover more about human physiological response to light and its relation to well-being,
                urbanisation
                and the creation of light-deprivation, the light filtering technology, and the CIRCA DIEM experience.
            </Fluid>
        </Layout>
    )
}

export default FluidFont
